<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="b-container">
      <div class="con-l">
        <div class="mod1 flex flex-v">
          <div class="mod3-b flex-11">
            <div class="b-smtit">
              <span class="bt">区域概况</span>
            </div>
            <ul class="mod1-gk">
              <li>
                <img src="https://home/ResImg/wt3.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">户籍人口</h6>
                  <p class="num" style="color: #489ffe">
                    {{ county.registeredPop }}万
                  </p>
                </div>
              </li>
              <li>
                <img src="https://home/ResImg/wt4.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">行政村数</h6>
                  <p class="num" style="color: #85b1ff">
                    {{ county.administrativeVil }}
                  </p>
                </div>
              </li>
              <li>
                <img src="https://home/ResImg/wt6.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">村民小组数</h6>
                  <p class="num" style="color: #90ffad">
                    {{ county.groupVil }}
                  </p>
                </div>
              </li>
              <li>
                <img src="https://home/ResImg/wt7.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">教育事业</h6>
                  <p class="num" style="color: #ffc55a">
                    在校生{{ county.internalStu }}万 <br />
                    老师{{ county.teacher }}人
                  </p>
                </div>
              </li>
              <li>
                <img src="https://home/ResImg/wt5.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">商业电商</h6>
                  <p class="num" style="color: #45f5ff">
                    {{ county.eCommerce }}亿
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div class="mod1-b flex-11">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">第二产业发展情况</span>
              </div>
              <div class="chart" ref="chart1"></div>
            </div>

            <div class="col col2">
              <div class="b-smtit">
                <span class="bt">土地情况</span>
              </div>
              <div class="chart" ref="chart3"></div>
            </div>
          </div>
          <div class="mod2-b flex-11">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">一村一品</span>
              </div>
              <div class="scroll-list flex-1 flex flex-v">
                <div class="row-t">
                  <div style="width: 34%">乡镇名称</div>
                  <div style="width: 32%">产物</div>
                  <div style="width: 34%">产量</div>
                </div>
                <div class="scrollbb">
                  <vue-seamless-scroll
                    :data="scroll2List"
                    :class-option="scrollOption"
                    class="scrollb"
                    style="height: 150px"
                  >
                    <div class="scrollbox flex-1">
                      <div
                        class="row"
                        v-for="(item, index) in scroll2List"
                        :key="index"
                      >
                        <div style="width: 34%">{{ item.name }}</div>
                        <div style="width: 32%">
                          <span class="cm">{{ item.cata }}</span>
                        </div>
                        <div style="width: 34%">
                          <span class="cy">{{ item.amount }}</span>
                        </div>
                      </div>
                    </div>
                  </vue-seamless-scroll>
                  <span class="linebg"></span>
                </div>
              </div>
            </div>

            <div class="col col2">
              <div class="b-smtit">
                <span class="bt">农业GDP 2017-2021</span>
              </div>
              <div class="chart" ref="chart2" ></div>
            </div>
          </div>
        </div>

        <div class="mod3">
          <!-- <div class="b-pub-tt flex">
            <span class="bt">乡村文明</span>
          </div> -->
          <div class="b-smtit">
            <span class="bt bt1">乡村文明</span>
          </div>
          <ul class="list">
            <li class="item" v-for="(item, index) in wenmingList" :key="index">
              <div class="imgbox">
                <img :src="item.img" class="img img_full" alt="" />
              </div>
              <div class="info">
                <h6 class="title ellipsis">{{ item.title }}</h6>
                <div class="bot">
                  <span class="num">{{ item.num }}</span>
                  <span class="level" v-if="item.level">{{ item.level }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="mod-cen">
          <div class="cen-menu">
            <span class="lk">智慧城市</span>
            <span class="lk cur">数字乡村振兴</span>
            <span class="lk">新亨电商</span>
            <span class="lk">智慧文旅</span>
          </div>
          <div class="cen-top">
            <li>
              <span class="tit">农业产能(万吨)</span>
              <p class="num">{{ webData.agriculturalCap }}</p>
            </li>
            <li class="line"></li>
            <li>
              <span class="tit">农业产量(万吨)</span>
              <p class="num">{{ webData.agriculturalPro }}</p>
            </li>
            <li class="line"></li>
            <li>
              <span class="tit">年度总销售额(万元)</span>
              <p class="num">{{ webData.annualGro }}</p>
            </li>
          </div>
          <div class="cen-map">
            <!-- <img src="https://home/ResImg/wt8.png" class="pic" alt="" /> -->
            <img src="https://home/ResImg/gldyjj.gif" class="pic" alt="">
            <!-- <div
              class="item item1"
              v-for="(item, index) in villageInto"
              :key="index"
              :class="index === indexNum ? 'active' : 'active1'"
            >
              <div class="box">
                <div class="info">
                  <h6 class="title">{{ item.title }}</h6>
                  <div class="txt">
                    <p>
                      {{ item.introduce }}
                    </p>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="cen-top b">
            <li>
              <h6 class="tit">产业兴旺</h6>
              <p class="num">{{ webData.thrivingBus }}</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">生态宜居</h6>
              <p class="num">{{ webData.pleasantLiv }}</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">乡风文明</h6>
              <p class="num">{{ webData.ruralCiv }}</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">治理有效</h6>
              <p class="num">{{ webData.effectiveGov }}</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">生活富裕</h6>
              <p class="num">{{ webData.beWellOff }}</p>
            </li>
          </div>
        </div>
      </div>
      <div class="con-r">
        <div class="mod7 flex-1 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">产业振兴</span>
          </div>
          <span class="txt">{{ webData.industry }}</span>
        </div>
        <div class="mod7 flex-1 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">人才振兴</span>
          </div>
          <span class="txt">{{ webData.talents }}</span>
        </div>
        <div class="mod7 flex-1 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">文化振兴</span>
          </div>
          <span class="txt">{{ webData.culture }}</span>
        </div>
        <div class="mod7 flex-1 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">生态振兴</span>
          </div>
          <span class="txt">{{ webData.zoology }}</span>
        </div>
        <div class="mod7 flex-1 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">组织振兴</span>
          </div>
          <span class="txt">{{ webData.organization }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head_big.vue";
let Echarts = require("echarts/lib/echarts");
import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import axios from "axios";
export default {
  data() {
    return {
      headTitle: "",
      wenmingList: [
        {
          img:"https://media.shouxinfund.com/jieyang/wt.jpg"
        },
        {
          img: "https://media.shouxinfund.com/jieyang/wt1.jpg"
        },
        {
          img:"https://media.shouxinfund.com/jieyang/wt2.jpg"
        },
        {
          img: "https://media.shouxinfund.com/jieyang/wt.jpg"
        }
      ],
      scroll2List: [
        {
          name: "",
          cata: "",
          amount: "",
        },
        {
          name: "",
          cata: "",
          amount: "",
        },
        {
          name: "",
          cata: "",
          amount: "",
        },
        {
          name: "",
          cata: "",
          amount: "",
        },
        {
          name: "",
          cata: "",
          amount: "",
        },
      ],
      villageInto: [],
      chart1: null,
      chart2: null,
      chart3: null,
      webData: {},
      county: {},
      secondaryInd: [],
      agriculturalGDP: {},
      landCon: [],
      loaded: false,
      indexNum: 0,
    };
  },
  components: {
    Head,
  },
  computed: {
    scrollOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {},
  mounted() {
    //   setInterval(() => {
    //   if(this.indexNum>=12){
    //     this.indexNum =0
    //     return
    //   }else{
    //     this.indexNum++
    //     console.log('this.indexNum===>',this.indexNum);
    //   }
    // }, 3100);
    

    this.loaded = true;
    this.getData();
    // this.init1();
    this.getsecondaryInd();
    // this.init2();
    this.getagriculturalGDP();
    // this.init3();
    this.getlandCon();

    window.addEventListener("resize", () => {
      this.chart1.resize();
      this.chart2.resize();
      this.chart3.resize();
    });
  },
  beforeDestroy() {
    // clearInterval();
  },
  methods: {
    getData() {
      axios
        .get("/10szxc.json",{
          params:{
            // timestamp:new Date().getTime()
            random:Math.random()
          }
        })
        .then((res) => {
          // 处理响应
          // console.log("数据:"+res.data);
          let dataObject = res.data; // 这里假设获取到的数据是一个 Object 类型的数据
          // console.log(dataObject.data);
          //
          let jsonString = JSON.stringify(dataObject); // 将 Object 类型的数据转换为字符串类型的 JSON 数据
          // console.log(jsonString) // 输出字符串类型的 JSON 数据
          let jsonObject = JSON.parse(jsonString);

          this.webData = jsonObject;
          this.county = jsonObject.county;
          this.villageInto = jsonObject.villageInto;
          console.log("webData======>", this.webData);
          console.log(
            "webData.country======>",
            jsonObject.county.registeredPop
          );
          //
          // this.schoolTotal = jsonObject.schoolTotal;
          //标题
          this.headTitle = jsonObject.bigTitle;
          this.scroll2List = jsonObject.oTOP;
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log(error.response.headers);
        });
    },

    async getsecondaryInd() {
      const res = await axios.get("/10szxc.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.secondaryInd = jsonObject.secondaryInd;

      this.init1();
    },
    init1() {
      this.chart1 = Echarts.init(this.$refs.chart1);

      let chartData = [];
      chartData = this.secondaryInd;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|产业收入}\n\n{b|" + sum + "万元}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(25),
                padding: [nowSize(-20), 10, 0, nowSize(80)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(18),
                padding: [nowSize(5), 0, 0, nowSize(74)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          padding:[0,0,0,10],
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(14),
          itemHeight: nowSize(14),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(15),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return (
              "{name|" +
              name +
              "}" +
              "{value|" +
              value +
              "万元}" +
              "{percent|" +
              p +
              "%}"
            );
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },

              value: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#03b581",
              },

              percent: {
                fontSize: nowSize(25),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },

        color: ["rgb(86,230,215)", "rgb(51,120,255)", "rgb(255,233,156)", "rgb(210,61,226)"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["52%", "65%"],
            center: ["24%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["46%", "48%"],
            center: ["24%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#48b1ea",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart1.setOption(option, true);

      this.tootipTimer1 && this.tootipTimer1.clearLoop();
      this.tootipTimer1 = 0;
      this.tootipTimer1 = loopShowTooltip(this.chart1, option, {});
    },

    async getagriculturalGDP() {
      const res = await axios.get("/10szxc.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.agriculturalGDP = jsonObject.agriculturalGDP;
      console.log(this.agriculturalGDP.GDP);
      this.init2();
    },

    init2() {
      console.log(this.agriculturalGDP.GDP);

      this.chart2 = Echarts.init(this.$refs.chart2);
      let option = {
        title: {
          text: "单位为:吨",
          top: nowSize(1),
          right: nowSize(1),
          textStyle: {
            fontSize: nowSize(16),
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(10),
          right: nowSize(10),
          bottom: nowSize(10),
          top: nowSize(25),
          containLabel: true,
        },

        legend: {
          x: "right",
          y: "top",
          icon: "rect",
          itemWidth: nowSize(14),
          itemHeight: nowSize(14),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(18),
          },
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            data: ["2017", "2018", "2019", "2020", "2021"],
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(18),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            // max: 12000,
            //  interval: 1000,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(18), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#023748"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "",
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize: nowSize(18),
              },
            },
            showBackground: false,
            barWidth: nowSize(14),
            // data: [3720, 3555, 2832, 3868, 2473],
            data: this.agriculturalGDP.GDP,
            smooth: true,
            itemStyle: {
              normal: {
                color: "#3399ff",
                barBorderRadius: 0,
              },
            },
          },
        ],
      };
      this.chart2.setOption(option, true);

      this.tootipTimer2 && this.tootipTimer2.clearLoop();
      this.tootipTimer2 = 0;
      this.tootipTimer2 = loopShowTooltip(this.chart2, option, {});
    },

    async getlandCon() {
      const res = await axios.get("/10szxc.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.landCon = jsonObject.landCon;
      this.init3();
    },
    init3() {
      this.chart3 = Echarts.init(this.$refs.chart3);

      let chartData = [];
      chartData = this.landCon;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|农田面积}\n\n{b|" + sum + "亩}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(25),
                padding: [nowSize(-20), 10, 0, nowSize(80)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(18),
                padding: [nowSize(-20), 10, 0, nowSize(80)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          padding:[0,0,0,20],
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(14),
          itemHeight: nowSize(14),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(15),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return (
              "{name|" +
              name +
              "}" +
              "{value|" +
              value +
              "亩}" +
              "{percent|" +
              p +
              "%}"
            );
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(25),
                height: nowSize(20),
                width: nowSize(100),
                // padding: [0, 0, 0, 0],
                color: "#fff",
              },

              value: {
                fontSize: nowSize(25),
                height: nowSize(20),
                width: nowSize(70),
                padding: [0,10, 0, 5],
                color: "#03b581",
              },

              percent: {
                fontSize: nowSize(25),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },
        color: ["rgb(86,230,215)", "rgb(51,120,255)", "rgb(210,61,226)"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["52%", "65%"],
            center: ["24%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["46%", "48%"],
            center: ["24%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#178ac9",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart3.setOption(option, true);

      this.tootipTimer3 && this.tootipTimer3.clearLoop();
      this.tootipTimer3 = 0;
      this.tootipTimer3 = loopShowTooltip(this.chart3, option, {});
    },
  },
};
</script>

<style scoped>
.page {
  background: url(https://media.shouxinfund.com/jieyang/map_xc.jpg) no-repeat center;
  background-size: 100% 100%;
  background-color: #0a224b;
}

.mod1 {
  height:75%;
  margin-bottom: 1%;
}

.mod3 {
  height: 24%;
}
.mod7 {
  height: 20%;
}

.mod1-gk {
  margin-top: 5%;
  height: 14%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mod1-gk li {
  display: flex;
  align-items: center;
}

.mod1-gk .icon {
  width: 90px;
  height: 100%;
}
.mod1-gk .info {
  flex: 1;
  overflow: hidden;
}
.mod1-gk .tit {
  font-size: 20px;
  color: #fff;
}
.mod1-gk .num {
  font-size: 26px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
}
.mod3-b {
  margin-top: 2%;
  height: 20%;
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px; */
}
.mod1-b {
  margin-top: 2%;
  height: 40%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
 
.mod1-b .chart {
  height: calc(100% - 20px);
  flex: 1;
}

.mod1-b .grp {
  margin-top: 40px;
}
.mod1-b .grp .num {
  font-size: 24px;
  font-family: "YouSheBiaoTiHei";
}
.mod1-b .grp .fz {
  font-size: 18px;
  color: #fff;
}
.mod1-b .tip {
  font-size: 20px;
  color: #fff;
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mod1-b .tip .star {
  width: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.mod2-b {
  height: 25%;
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
}
.mod1-b .tip .wz {
  font-size: 20px;
  color: red;
  margin-left: 5px;
}

.mod2-b .col {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.mod2-b .col1 {
  width: 30%;
  /* height: 40%; */
}
.mod2-b .col2 {
  width: 60%;
}
.mod2-b .chart {
  height: calc(100% - 5px);
  flex: 1;
}

.mod3 {
  width: calc(100% + 50px);
}
.mod3 .list {
  display: flex;
  justify-content: space-between;
}
.mod3 .list .item {
  display: flex;
  align-items: center;
  width: 24%;
}
.mod3 .list .item .imgbox {
  width: 100%;
  height: 180px;
  border: 4px solid #041f48;
  border-radius: 8px;
  margin-right: 10px;
}
.mod3 .list .info {
  flex: 1;
  overflow: hidden;
}
.mod3 .list .title {
  font-size: 21px;
  color: #fff;
  margin-bottom: 5px;
}
.mod3 .list .bot {
  display: flex;
  align-items: center;
}
.mod3 .list .num {
  font-size: 24px;
  color: #45f5ff;
  font-family: "YouSheBiaoTiHei";
}
.mod3 .list .level {
  font-size: 22px;
  color: #00ffb5;
  height: 34px;
  line-height: 34px;
  border: 1px solid #00ffb5;
  border-radius: 6px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px;
  font-family: "YouSheBiaoTiHei";
}

.mod4 .list {
  display: flex;
  justify-content: space-between;
}
.mod4 .list .item {
  width: 50%;
  position: relative;
}
.mod4 .list .pic {
  width: 70%;
  margin: 0 auto;
  display: block;
}
.mod4 .list .grp {
  position: absolute;
  background: url(../assets/images/sz_20.png) no-repeat center;
  background-size: cover;
  width: 220px;
  height: 55px;
  padding-left: 85px;
}
.mod4 .list .fz {
  font-size: 21px;
  color: #66b7ff;
}
.mod4 .list .num {
  font-size: 21px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
}
.mod4 .list .grp1 {
  left: 10%;
  top: 80px;
}
.mod4 .list .grp2 {
  left: 10%;
  top: 240px;
}
.mod4 .list .grp3 {
  right: 10%;
  top: 240px;
}

.mod6 .list {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.mod6 .list .item {
  display: flex;
  align-items: center;
  width: 32%;
}
.mod6 .list .imgbox {
  width: 120px;
  height: 70px;
  border: 4px solid #0a2857;
  border-radius: 8px;
  margin-right: 15px;
}
.mod6 .list .info {
  flex: 1;
  overflow: hidden;
}
.mod6 .list .title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 5px;
}
.mod6 .list .font {
  font-size: 20px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
}
.mod6 .list .font span {
  font-family: inherit;
}
.mod6 .list .item:nth-child(1) .font span {
  color: #489ffe;
}
.mod6 .list .item:nth-child(2) .font span {
  color: #85b1ff;
}
.mod6 .list .item:nth-child(3) .font span {
  color: #90ffad;
}

.mod7 .detail {
  display: flex;
  justify-content: space-between;
}
.mod7 .col {
  width: 33.3%;
  display: flex;
  flex-direction: column;
}
.mod7 .chart {
  height: calc(100% - 100px);
  flex: 1;
}
.mod7 .b-smtit {
  margin-bottom: 0;
}
.mod7 .txt {
  margin-top: 1%;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  line-height:1.5;
  letter-spacing: 6px;
  /* text-indent: 2em; */
  font-size: 24px;
  /* 溢出部分隐藏 */
  overflow: hidden;
 /* 溢出部分用省略号代替 */
 text-overflow: ellipsis;
 /* 弹性伸缩盒子模型显示 */
 display: -webkit-box;
 /* 限制一个块元素显示的文本行数 */
 -webkit-line-clamp: 3;
 /* 设置伸缩盒对象子元素的排列方式 */
 -webkit-box-orient: vertical;
  color: #fff;
}

.con-c {
  position: relative;
}

.cen-bot {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
  padding: 0 4%;
  z-index: 10;
}
.cen-bot li {
  display: flex;
  align-items: center;
}
.cen-bot .icon {
  width: 44px;
  margin-right: 16px;
}
.cen-bot li span {
  font-size: 26px;
  color: #fff;
  font-family: "RuiZiZhenYan";
  letter-spacing: 3px;
}

.cen-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}
.cen-map .pic {
  width: 100%;
  height: 100%;
  margin-left: 2%;
  max-width: initial;
}
.cen-map .item {
  position: absolute;
  width: 430px;
}
.cen-map .item .box {
  display: flex;
  background-color: rgba(24, 53, 73, 0.7);
  border: 1px solid #95a1ab;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
}
.cen-map .item .box2 {
  display: flex;
  background-color: rgba(0, 31, 51, 0.7);
  border: 1px solid #6cbcfd;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
}
.cen-map .item .imgbox {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 20px;
  border: 1px solid #95a1ab;
  overflow: hidden;
}
.cen-map .item .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cen-map .item .info {
  flex: 1;
  overflow: hidden;
}
.cen-map .item .title {
  font-size: 35px;
  color: #fff;
  margin-bottom: 6px;
}
.cen-map .item .txt {
  font-size: 18px;
  color: #ffffff80;
  line-height: 1.5;
}
.cen-map .item .tit {
  font-size: 18px;
  color: #0087d4;
}

.cen-map .item1 {
  top: 30%;
  left: 70%;
}
.cen-map .item:nth-child(2) {
  top: 57%;
  left: 15%;
}
.cen-map .item:nth-child(3) {
  top: 55%;
  left: 6%;
}
.cen-map .item:nth-child(4) {
  top: 47%;
  left: 23%;
}
.cen-map .item:nth-child(5) {
  top: 45%;
  left: 23%;
}
.cen-map .item:nth-child(6) {
  top: 40%;
  left: 15%;
}
.cen-map .item:nth-child(7) {
  top: 35%;
  left: 36%;
}
.cen-map .item:nth-child(8) {
  top: 51%;
  left: 33%;
}
.cen-map .item:nth-child(9) {
  top: 71%;
  left: 35%;
}
.cen-map .item:nth-child(10) {
  top: 48%;
  left: 50%;
}
.cen-map .item:nth-child(11) {
  top: 35%;
  left: 36%;
}
.cen-map .item:nth-child(12) {
  top: 37%;
  left: 46%;
}
.cen-map .item:nth-child(13) {
  top: 35%;
  left: 45%;
}
.active {
  opacity: 1;
}
.active1 {
  opacity: 0;
}
.cen-top.b {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
  padding: 0 5%;
}

.cm {
  color: #ffd105;
}
.cy {
  color: #77a2f2;
}
</style>
